<template>
	<Layout>
		<Header />
		<section class="columns is-multiline analytics">
			<div v-for="i in over" :key="i.id"  class="column is-one-fifth">
				<div class="dashboard__box analytics__box p-3" :class="setExpression(i)">
					<div class="icon">
						<span v-html="require(`!html-loader!../assets/svg/${setExpression(i)}.svg`)"></span>
					</div>
					<div class="content">
						<h4 class="dashboard__box__value analytics__value">
							{{ i.total }}
						</h4>
						<span class="dashboard__box__type analytics__total">{{ setExpression(i) }}</span>
					</div>
				</div>
			</div>
			<div v-if="screens.length > 0" class="column is-one-fifth">
				<div class="dashboard__box analytics__box p-3 screens">
					<div class="icon">
						<span v-html="require(`!html-loader!../assets/svg/screens.svg`)"></span>
					</div>
					<div class="content">
						<h4 class="dashboard__box__value analytics__value">
							{{ screens.length }}
						</h4>
						<span class="dashboard__box__type analytics__total">Screens</span>
					</div>
				</div>
			</div>
		</section>

    <section class="columns is-multiline is-mobile column-video">
      <article class="column is-12-mobile">
        <div class="dashboard__panel is-relative">
          <video id="video-face" width="720" height="560" autoplay muted />
        </div>
      </article>
      <article class="column is-12-mobile column-slides">
        <div class="dashboard__panel analytics__bars is-relative">
					<div v-for="s in screens" :key="`screen-${s.id}`" class="analytics__bars__item">
						<span>{{ s.name }}</span>
						<b-progress type="is-success" :value="getPercent(s.views)" show-value format="percent"></b-progress>
					</div>
        </div>
      </article>
    </section>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Api from '@/services/api'
import { responsive } from '@/mixins/responsive'
import { io } from 'socket.io-client'
import * as faceapi from 'face-api.js'
import moment from 'moment'
import server from '@/mixins/server'

export default {
	name: 'Analytics',
	mixins: [
        responsive,
        server
    ],
	components: {
		Layout,
		Header
	},
    data () {
        return {
            socketIO: io('https://ec2-3-135-207-172.us-east-2.compute.amazonaws.com'),
            debug: false,
            stream: null,
            faces: [],
            lastTotalFaces: 0,
            currentTotalFaces: 0,
            placeId: null,
            placeCode: null,
            currentScreen: null,
            screens: [],
            lastTime: new Date().getTime(),
            dbDetections: [],
            interval: null,
            over: [
                { expression: 'passerby', total: 0 },
                { expression: 'total', total: 0 },
                { expression: 'neutral', total: 0 },
                { expression: 'happy', total: 0 },
                { expression: 'surprised', total: 0 },
                { expression: 'angry', total: 0 },
                { expression: 'sad', total: 0 },
                { expression: 'disgusted', total: 0 },
                { expression: 'fearful', total: 0 }
            ]
        }
    },
    mounted() {
        this.init()
    },
    destroyed() {
        this.closeWebCam()
        this.closeInterval()
    },
	methods: {
        init() {
            if (this.$route.query && this.$route.query.debug) {
                this.debug = true
            }

            if (this.$route.params && this.$route.params.place) {
                this.placeId = parseInt(this.$route.params.place)

                this.findPlace()
                // this.findScreensByPlace()
                this.registerSocketIo()
            }

            let savedDetections = localStorage.getItem('detections')

            if (savedDetections) {
                this.dbDetections = JSON.parse(savedDetections)
            }
        },
        registerSocketIo() {
            this.socketIO.on('podash', (msg) => {
                // console.log(msg)
                if (this.placeId && this.placeId == msg.placeId && msg.event && msg.event == 'currentScreen') {
                    console.log('Passou aqui')
                    this.currentScreen = msg.screenId
                }
            })
        },
        startWebCam() {
            setTimeout(() => {
                this.startMovie()
                this.startInterval()
            }, 1500)
        },
        closeWebCam() {
            this.stream.getTracks().forEach((track) => {
                if (track.readyState == 'live' && track.kind === 'video') {
                    track.stop()
                }
            })
        },
        findPlace() {
            if (this.placeId) {
                Api.get(`place/findById/${this.placeId}`)
                    .then(({ data }) => {
                        this.placeCode = data.code

                        this.screens = data.screens.map(d => {
                            return {
                                id: d.id,
                                name: d.name,
                                views: 0,
                                expressions: {
                                    angry: 0,
                                    disgusted: 0,
                                    fearful: 0,
                                    happy: 0,
                                    neutral: 0,
                                    sad: 0,
                                    surprised: 0
                                }
                            }
                        })

                        this.startWebCam()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        },
        findScreensByPlace() {
            if (this.placeId) {
                Api.post('screen/filterByPlace', {
                    place: this.placeId,
                    status: 1
                }).then(({ data }) => {
                    this.screens = data.map(d => {
                        return {
                            id: d.id,
                            name: d.name,
                            views: 0,
                            expressions: {
                                angry: 0,
                                disgusted: 0,
                                fearful: 0,
                                happy: 0,
                                neutral: 0,
                                sad: 0,
                                surprised: 0
                            }
                        }
                    })

                    this.startWebCam()
                }).catch((err) => {
                    console.log(err)
                })
            }
        },
        startMovie() {
            const video = document.getElementById('video-face')

            Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
                faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
                faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
                faceapi.nets.faceExpressionNet.loadFromUri('/models')
            ]).then(() => {
                navigator.mediaDevices.getUserMedia({ video: true, audio: false })
                    .then((stream) => {
                        this.stream = stream
                        video.srcObject = stream;
                    })
                    .catch(function(err) {
                        console.log('Erro ao iniciar o vídeo, detalhes: ' + err)
                    })
            })

            // const getTop = (l) => {
            //     return l.map((a) => a.y).reduce((a, b) => Math.min(a, b));
            // }

            const getMeanPosition = (l) => {
                return l.map((a) => [a.x, a.y]).reduce((a, b) => [a[0] + b[0], a[1] + b[1]]).map((a) => a / l.length)
            }

            const checkFaceFront = (detection) => {
                let eyeRight = getMeanPosition(detection["landmarks"].getRightEye())
                let eyeLeft = getMeanPosition(detection["landmarks"].getLeftEye())
                let nose = getMeanPosition(detection["landmarks"].getNose())

                let faceVal = ((eyeLeft[0] + (eyeRight[0] - eyeLeft[0]) / 2 - nose[0]) / detection['detection']["_box"]["_width"]).toFixed(2)

                if (faceVal >= -0.01 && faceVal <= 0.01) {
                    return true
                }

                return false
            }

            const getExpression = (expressions) => {
                let count = 0
                let expression = 'neutral'

                Object.entries(expressions).forEach(e => {
                    if (e[1] > count) {
                        count = e[1]
                        expression = e[0]
                    }
                })

                return expression
            }

            video.addEventListener('play', () => {
                const rect = video.getBoundingClientRect()
                const canvas = faceapi.createCanvasFromMedia(video)
                canvas.style.position = 'absolute'
                canvas.style.top = `${rect.top}px`
                canvas.style.left = `${rect.left}px`

                document.body.append(canvas)
                const displaySize = { width: parseInt(rect.width), height: parseInt(rect.height) }
                faceapi.matchDimensions(canvas, displaySize)

                setInterval(async () => {
                    const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions().withFaceDescriptors()
                    const resizedDetections = faceapi.resizeResults(detections, displaySize)

                    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)

                    faceapi.draw.drawDetections(canvas, resizedDetections)
                    faceapi.draw.drawFaceLandmarks(canvas, resizedDetections)
                    faceapi.draw.drawFaceExpressions(canvas, resizedDetections)

                    this.faces = []
                    let passerby = 0
                    this.currentTotalFaces = 0
                    if (detections.length > 0) {
                        detections.forEach((d) => {
                            /*
                            angry
                            disgusted
                            fearful
                            happy
                            neutral
                            sad
                            surprised
                            */

                            if (checkFaceFront(d)) {
                                this.faces.push(getExpression(d.expressions))
                                ++this.currentTotalFaces
                            } else {
                                ++passerby
                            }
                        })

                        if (this.currentTotalFaces > 0 && this.debug) {
                            console.log('Detectando: ' + this.currentTotalFaces + ' Face(s)')
                        }
                    }

                    let currentTime = new Date().getTime()

                    if (currentTime - this.lastTime >= 2000) {
                        this.lastTime = currentTime

                        if (passerby > 0) {
                            this.$set(this.over[0], 'total', (this.over[0]['total'] + passerby))
                        }

                        if (this.currentTotalFaces > 0 && this.currentTotalFaces > this.lastTotalFaces && this.placeId && this.currentScreen) {
                            if (this.debug) {
                                console.log('Vai adicionar: ' + (this.currentTotalFaces - this.lastTotalFaces) + ' Face(s)')
                                console.log(this.faces)
                            }

                            for (let i = this.lastTotalFaces; i < this.currentTotalFaces; i++) {
                                if (this.debug) {
                                    console.log('Vai Salvar: ' + this.faces[i] + ', às: ' + moment().format('YYYY-MM-DD HH:mm:ss'))
                                }

                                this.dbDetections.push({
                                    data: moment().format('YYYY-MM-DD HH:mm:ss'),
                                    place: this.placeCode,
                                    screen_id: this.currentScreen,
                                    expression: this.faces[i]
                                })

                                this.addViewAndExpression(this.faces[i])

                                localStorage.setItem('detections', JSON.stringify(this.dbDetections))
                            }
                        }

                        this.lastTotalFaces = this.currentTotalFaces;
                    }
                }, 100);
            })
        },
        startInterval() {
            this.interval = setInterval(() => {
                if (this.dbDetections.length > 0 && !this.debug) {
                    console.log('Send detections to server')
                    Api.post(`${this.getUrlServer()}/faces-detections`, { detections: this.dbDetections })
                        .then(() => {
                            this.dbDetections = []
                            localStorage.removeItem('detections')
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            }, 300000)
        },
        addViewAndExpression(expression) {
            if (this.currentScreen) {
                let screenIndex = this.screens.findIndex(s => s.id == this.currentScreen)
                let overIndex = this.over.findIndex(s => s.expression == expression)

                this.$set(this.over[overIndex], 'total', (this.over[overIndex]['total'] + 1))
                this.$set(this.screens[screenIndex], 'views', (this.screens[screenIndex]['views'] + 1))

                this.$set(this.over[1], 'total', (this.over[1]['total'] + 1))
            }
        },
        closeInterval() {
            if (this.interval) {
                clearInterval(this.interval)
            }
        },
        getPercent(value) {
            if (this.over[1]['total']) {
                return (100 * value) / this.over[1]['total']
            }

            return 0
        },
				setExpression (over) {
					return over.expression == 'total' ? 'audience' : over.expression
				}
	}
}
</script>
